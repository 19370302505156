<template>
  <div class="home">
    <h1>HOME</h1>
    <h1>{{ this.program.title }}</h1>
  </div>
</template>

<script>
import { usePage } from "~/composables";

const page = usePage();
</script>

<style scoped>
.home {
  background-color: transparent !important;
  background: transparent !important;
  pointer-events: none;
}
</style>
